
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

// @ts-ignore
import _ from 'lodash-es';

import CloseButton from '@/components/CloseButton.vue';
import SearchTextField from '@/components/form/SearchTextField.vue';
import CommonPopup from '@/components/popup/CommonPopup.vue';
import DiscardPopup from '@/components/popup/DiscardPopup.vue';

import {
  getNodes,
  getNetworkArcs,
  getNetworkArcSounds,
  putNetworkArcSounds,
  deleteNetworkArcSounds,
} from '@/services/axios';
import DeleteTagPopup from '@/components/sound-editor/DeleteTagPopup.vue';
import AssignSoundConfirmPopup from '@/components/sound-editor/AssignSoundConfirmPopup.vue';

@Component({
  components: {
    CloseButton,
    SearchTextField,
    DeleteTagPopup,
    AssignSoundConfirmPopup,
    CommonPopup,
    DiscardPopup,
  },
})
export default class AssignAutomaticSoundPopup extends Vue {
  @State opId!: number;
  @State soundGroups!: any[];

  @Prop() readonly network!: any;
  @Prop() readonly version!: any;

  @Watch('allTagList', { deep: true })
  onAllTagListChanged() {
    this.isJustFinishedSave = false;
  }

  @Watch('currentTagList', { deep: true })
  onCurrentTagListChanged() {
    this.setGraphicLines();
  }

  searchNodeText: string = '';
  searchSoundGroupText: string = '';
  preventDismissDropdown: boolean = false;
  nodes: any = [];
  networkArcs: any = [];
  isSaving: boolean = false;
  isConfirmSavePopupVisible: boolean = false;
  isCommonPopupVisible: boolean = false;
  isDiscardPopupVisible: boolean = false;
  isJustFinishedSave: boolean = false;

  isMultipleNodeSelection: boolean = false;
  isAllSelected: boolean = false;

  selectedNode: any = null;
  selectedNodeIndex: number = 0;
  selectedMultipleNodeIndexes: any = [];
  tags: any = [
    { name: 'NEXT', flag: 1 },
    { name: 'ARR', flag: 2 },
  ];
  tagColors: any = ['#3C57DD', '#852DD2', '#F655B6', '#EA4868', '#FF7A66', '#1FAD91'];
  distanceType: any = [
    { name: 'หลังออก', flag: 0 },
    { name: 'ก่อนถึง', flag: 1 },
  ];
  distanceUnit: any = [
    { name: 'ม.', flag: 0 },
    { name: '%', flag: 2 },
  ];
  currentTagList: any = [];
  currentMultipleTagList: any = [];
  allTagList: any = [];
  previousAllTagList: any = [];
  diffTag: number = 0;
  extraGraphicLinePadding: number = 0;
  graphicLines: any = [];
  defaultNodeLineBarWidth: number = 0;

  activeTagNameDropdownIndex: any = null;
  activeSoundGroupDropdownIndex: any = null;
  activeDistanceFromIndex: any = null;
  activeDistanceFromUnitDropdownIndex: any = null;
  activeDistanceToUnitDropdownIndex: any = null;
  activeDistanceToIndex: any = null;
  activeDistanceFromTypeDropdownIndex: any = null;
  activeDistanceToTypeDropdownIndex: any = null;
  activeTagIndex: any = null;

  isDeletingTag: boolean = false;

  get excludeFirstNodes() {
    let nodes = _.cloneDeep(this.nodes);
    nodes.shift();
    return nodes;
  }

  get filteredNodes() {
    let nodeList = _.filter(this.excludeFirstNodes, (node: any) => {
      return (
        (node.i18n_name &&
          node.i18n_name.toLowerCase().includes(this.searchNodeText.toLowerCase())) ||
        (node.dflt_name && node.dflt_name.toLowerCase().includes(this.searchNodeText.toLowerCase()))
      );
    });
    return nodeList;
  }

  get originNodeName() {
    return (
      this.nodes[this.selectedNodeIndex].i18n_name ||
      this.nodes[this.selectedNodeIndex].dflt_name ||
      ''
    );
  }

  get destinationNodeName() {
    return (
      this.nodes[this.selectedNodeIndex + 1].i18n_name ||
      this.nodes[this.selectedNodeIndex + 1].dflt_name ||
      ''
    );
  }

  get distance() {
    return parseInt(this.networkArcs[this.selectedNodeIndex].arc_dist.toFixed(0));
  }

  get filteredSoundGroups() {
    let list = _.filter(this.soundGroups, (sg: any) => {
      return sg.name && sg.name.toLowerCase().includes(this.searchSoundGroupText.toLowerCase());
    });
    return list;
  }

  setGraphicLines() {
    if (this.$refs.graphicLineBar) {
      this.graphicLines = [];
      // @ts-ignore
      this.defaultNodeLineBarWidth = this.$refs.graphicLineBar.getBoundingClientRect().width - 40;

      if (this.currentTagList.length > 0) {
        this.extraGraphicLinePadding = 0;

        _.forEach(this.currentTagList, (tagData: any) => {
          let marginLeft = 0;
          let width = 0;
          const distance = this.distance + this.getExtraDistanceMetre(this.selectedNodeIndex);

          if (this.isDistanceTypeAfterDeparture(tagData.fromDistanceType.flag)) {
            //หลังออก
            if (this.isDistanceUnitMetre(tagData.fromDistanceUnit.flag)) {
              marginLeft = tagData.fromDistance / this.distance; //เมตร
            } else {
              marginLeft = tagData.fromDistance / 100; //เปอร์เซ็น
            }
            if (this.isDistanceTypeAfterDeparture(tagData.toDistanceType.flag)) {
              //หลังออก
              if (this.isDistanceUnitMetre(tagData.toDistanceUnit.flag)) {
                width = tagData.toDistance / this.distance - marginLeft; //เมตร
              } else {
                width = (parseFloat(tagData.toDistance) - marginLeft * 100) / 100; //เปอร์เซ็น
              }
            } else {
              if (this.isDistanceUnitMetre(tagData.toDistanceUnit.flag)) {
                //ก่อนถึง
                width = (this.distance - tagData.toDistance) / this.distance - marginLeft; //เมตร
              } else {
                width = (100 - marginLeft * 100 - parseFloat(tagData.toDistance)) / 100; //เปอร์เซ็น
              }
            }
          } else {
            //ก่อนถึง
            if (this.isDistanceUnitMetre(tagData.fromDistanceUnit.flag)) {
              marginLeft = (this.distance - tagData.fromDistance) / this.distance; //เมตร
            } else {
              marginLeft = (100 - tagData.fromDistance) / 100; //เปอร์เซ็น
            }
            if (this.isDistanceTypeAfterDeparture(tagData.toDistanceType.flag)) {
              //หลังออก
              if (this.isDistanceUnitMetre(tagData.toDistanceUnit.flag)) {
                width = tagData.toDistance / this.distance - marginLeft; //เมตร
              } else {
                width = (parseFloat(tagData.toDistance) - marginLeft * 100) / 100; //เปอร์เซ็น
              }
            } else {
              if (this.isDistanceUnitMetre(tagData.toDistanceUnit.flag)) {
                //ก่อนถึง
                width = (this.distance - tagData.toDistance) / this.distance - marginLeft; //เมตร
              } else {
                width = (100 - (marginLeft * 100 + parseFloat(tagData.toDistance))) / 100; //เปอร์เซ็น
              }
            }
          }

          marginLeft *= 100;
          width *= 100;

          marginLeft = parseFloat(marginLeft.toFixed(2));
          width = parseFloat(width.toFixed(2));

          const linePercent = parseFloat((marginLeft + width).toFixed(2));

          let diffPercent = 0;
          if (linePercent > 100) {
            diffPercent = parseFloat((linePercent - 100).toFixed(2));
            this.extraGraphicLinePadding = this.defaultNodeLineBarWidth * (diffPercent / 100);
          }

          if (width < 0) {
            width = 0;
            if (this.extraGraphicLinePadding != 0) {
              this.extraGraphicLinePadding = 0;
            }
          }

          this.$nextTick(() => {
            const nodeLeft = this.$refs.nodeLeft;
            const nodeRight = this.$refs.nodeRight;

            if (nodeLeft && nodeRight) {
              // @ts-ignore
              const nodeLeftRect = nodeLeft.getBoundingClientRect();
              // @ts-ignore
              const nodeRightRect = nodeRight.getBoundingClientRect();

              const lineBarWidth = nodeRightRect.x - (nodeLeftRect.x + nodeLeftRect.width);

              let extraWidthPixels = 0;
              if (diffPercent > 0) {
                extraWidthPixels += ((this.extraGraphicLinePadding + 40) * diffPercent) / 100;
              }

              this.graphicLines.push({
                tag: _.get(tagData, 'tag.name', ''),
                marginLeftPercent: marginLeft,
                widthPercent: width,
                marginLeftPixel: (lineBarWidth * marginLeft) / 100,
                widthPixel: (lineBarWidth * width) / 100 + extraWidthPixels,
              });
            }
          });
        });
      }
    }
  }

  get canAddNewTag() {
    if (this.isMultipleNodeSelection) {
      return this.availableTags.length > 0 && !this.isMaxTagReached;
    } else {
      return this.currentTagList.length < this.tags.length;
    }
  }

  get isMaxTagReached() {
    let isReach: boolean = false;
    if (this.isMultipleNodeSelection) {
      _.forEach(this.selectedMultipleNodeIndexes, (index: number) => {
        if (this.allTagList[index].length == this.tags.length) {
          isReach = true;
          return false;
        }
      });
    }
    return isReach;
  }

  get availableTags() {
    if (this.isMultipleNodeSelection) {
      let usedTags: any = [];
      _.forEach(this.selectedMultipleNodeIndexes, (index: number) => {
        usedTags.push(_.map(this.allTagList[index], 'tag.flag'));
      });
      usedTags = _.flatten(usedTags);
      const flags = _.difference(_.map(this.tags, 'flag'), usedTags).sort();
      let availableTags: any = [];
      _.forEach(flags, (flag: number) => {
        availableTags.push(_.find(this.tags, { flag }));
      });
      return availableTags;
    } else {
      const usedTags = _.map(this.currentTagList, 'tag.flag');
      return _.filter(this.tags, (tag: any) => {
        return !usedTags.includes(tag.flag);
      });
    }
  }

  positiveCollapseMessage(tag: any) {
    let distance = parseInt(this.getDistance(this.selectedNodeIndex));

    let fromDistance = tag.fromDistance;
    let toDistance = tag.toDistance;

    if (this.isDistanceUnitMetre(tag.fromDistanceUnit.flag)) {
      fromDistance = (fromDistance / distance) * 100;
    }

    if (this.isDistanceUnitMetre(tag.toDistanceUnit.flag)) {
      toDistance = (toDistance / distance) * 100;
    }

    fromDistance = parseFloat(fromDistance);
    toDistance = parseFloat(toDistance);

    if (fromDistance > 100) {
      if (this.isDistanceTypeAfterDeparture(tag.fromDistanceType.flag)) {
        return '💡 ระยะเกิน 100% ของระยะทาง ‘หลังออก’ คือ ระยะที่เกยออกไปยังป้ายถัดไป';
      }
    }
    if (toDistance > 100) {
      if (this.isDistanceTypeAfterDeparture(tag.toDistanceType.flag)) {
        return '💡 ระยะเกิน 100% ของระยะทาง ‘หลังออก’ คือ ระยะที่เกยออกไปยังป้ายถัดไป';
      }
    }

    return null;
  }

  negativeCollapseMessage(tag: any) {
    let distance = parseInt(this.getDistance(this.selectedNodeIndex));

    let fromDistance = tag.fromDistance;
    let toDistance = tag.toDistance;

    if (this.isDistanceUnitMetre(tag.fromDistanceUnit.flag)) {
      fromDistance = (fromDistance / distance) * 100;
    }

    if (this.isDistanceUnitMetre(tag.toDistanceUnit.flag)) {
      toDistance = (toDistance / distance) * 100;
    }

    fromDistance = parseFloat(fromDistance);
    toDistance = parseFloat(toDistance);

    if (fromDistance < 0) {
      if (!this.isDistanceTypeAfterDeparture(tag.fromDistanceType.flag)) {
        return '💡 ระยะติดลบ ’ก่อนถึง’ คือ ระยะที่เกยออกไปยังป้ายถัดไป';
      }
    }
    if (toDistance < 0) {
      if (!this.isDistanceTypeAfterDeparture(tag.toDistanceType.flag)) {
        return '💡 ระยะติดลบ ’ก่อนถึง’ คือ ระยะที่เกยออกไปยังป้ายถัดไป';
      }
    }
    return null;
  }

  getTagColor(index: number) {
    return this.tagColors[index % 6];
  }

  getDistance(nodeIndex: number) {
    return this.networkArcs[nodeIndex].arc_dist.toFixed(0);
  }

  getExtraDistancePercent(nodeIndex: number) {
    if (this.isMultipleNodeSelection) {
      return 0;
    }
    let selfDistance = parseInt(this.getDistance(nodeIndex));
    let percent = 0;
    const nextArc = this.networkArcs[nodeIndex + 1];
    if (nextArc && parseInt(nextArc.arc_dist)) {
      const nextArcDistance = parseInt(nextArc.arc_dist.toFixed(0));
      if (nextArcDistance < 300) {
        percent = (nextArcDistance / selfDistance) * 100;
      } else {
        percent = (300 / nextArcDistance) * 100;
      }
    } else {
      percent = 100;
    }
    return parseFloat(percent.toFixed(2));
  }

  getExtraDistanceMetre(nodeIndex: number) {
    if (this.isMultipleNodeSelection) {
      return 0;
    }
    let selfDistance = parseInt(this.getDistance(nodeIndex));
    let distance = 0;
    const nextArc = this.networkArcs[nodeIndex + 1];
    if (nextArc && parseInt(nextArc.arc_dist)) {
      const nextArcDistance = parseInt(nextArc.arc_dist.toFixed(0));
      if (nextArcDistance < 300) {
        distance = nextArcDistance;
      } else {
        distance = 300;
      }
    } else {
      distance = selfDistance;
    }
    return distance;
  }

  getTags(node: any) {
    const index = _.findIndex(this.excludeFirstNodes, node);
    let tagList = _.filter(_.map(this.allTagList[index], 'tag'), (obj: any) => {
      return obj != null;
    });
    tagList = _.orderBy(tagList, ['flag'], ['asc']);
    return tagList;
  }

  loadNodes() {
    getNodes(this.opId, this.network)
      .then(res => {
        let arcNodes = _.map(this.networkArcs, 'nod_id');
        this.nodes = _.filter(res.data, (node: any) => {
          return arcNodes.includes(node.nod_id);
        });
        this.nodes = _.sortBy(this.nodes, (node: any) => {
          return arcNodes.indexOf(node.nod_id);
        });
        this.loadNetworkArcSounds();
      })

      .catch(err => {})
      .finally(() => {});
  }

  loadNetworkArcSounds() {
    getNetworkArcSounds(this.opId, this.network, this.version.net_ver)
      .then((res: any) => {
        this.prepareAllTagList(res.data);
      })
      .catch((err: any) => {})
      .finally(() => {});
  }

  putNetworkArcSounds(data: any) {
    this.isSaving = true;
    putNetworkArcSounds(this.opId, this.network, this.version.net_ver, data)
      .then((res: any) => {
        this.isCommonPopupVisible = true;
        this.isJustFinishedSave = true;
      })
      .catch((err: any) => {})
      .finally(() => {
        this.isSaving = false;
      });
  }

  deleteNetworkArcSounds() {
    this.isSaving = true;
    deleteNetworkArcSounds(this.opId, this.network, this.version.net_ver)
      .then((res: any) => {
        this.isCommonPopupVisible = true;
        this.isJustFinishedSave = true;
      })
      .catch((err: any) => {})
      .finally(() => {
        this.isSaving = false;
      });
  }

  prepareAllTagList(data: any) {
    this.allTagList = [];
    _.each(this.excludeFirstNodes, (node: any, index: number) => {
      this.allTagList.push(this.tagDataByArcMediaObject(_.find(data, { seq: index + 2 })));
    });
    this.previousAllTagList = _.cloneDeep(this.allTagList);
  }

  tagDataByArcMediaObject(object: any) {
    if (object) {
      let tagList: any = [];
      _.each(object.sound_dist_ref, (d: any, index: number) => {
        tagList.push({
          tag: _.find(this.tags, { flag: object.sound_flag[index] }) || null,
          tagError: null,
          soundGroup: _.find(this.soundGroups, { snd_grp_id: object.sound_grp[index] }),
          soundGroupError: null,
          fromDistance: object.sound_dist_ref[index][0],
          fromDistanceUnit: this.distanceUnit[
            object.sound_dist_ref_flag[index][0]
              .toString(2)
              .split('')
              .reverse()
              .map((flag: any) => parseInt(flag, 2))[1] || 0
          ],
          fromDistanceType: this.distanceType[
            object.sound_dist_ref_flag[index][0]
              .toString(2)
              .split('')
              .reverse()
              .map((flag: any) => parseInt(flag, 2))[0] || 0
          ],
          fromError: null,
          toDistance: object.sound_dist_ref[index][1],
          toDistanceUnit: this.distanceUnit[
            object.sound_dist_ref_flag[index][1]
              .toString(2)
              .split('')
              .reverse()
              .map((flag: any) => parseInt(flag, 2))[1] || 0
          ],
          toDistanceType: this.distanceType[
            object.sound_dist_ref_flag[index][1]
              .toString(2)
              .split('')
              .reverse()
              .map((flag: any) => parseInt(flag, 2))[0] || 0
          ],
          toError: null,
          error: null,
        });
      });
      return tagList;
    } else {
      return [];
    }
  }

  selectNode(node: any) {
    if (!this.isMultipleNodeSelection) {
      this.extraGraphicLinePadding = 0;
      this.selectedNode = node;
      this.selectedNodeIndex = _.findIndex(this.excludeFirstNodes, node);
      this.currentTagList = [];

      this.$nextTick(() => {
        // @ts-ignore
        this.defaultNodeLineBarWidth = this.$refs.graphicLineBar.getBoundingClientRect().width - 40;
        this.currentTagList = this.allTagList[this.selectedNodeIndex];
        _.each(this.currentTagList, (t: any, index: number) => {
          this.validateTags(index);
        });
      });
    }
  }

  isSelectedNode(node: any) {
    if (this.selectedNode) {
      return (
        `${this.selectedNode.sys_id},${this.selectedNode.lyr_id},${this.selectedNode.nod_id}` ===
        `${node.sys_id},${node.lyr_id},${node.nod_id}`
      );
    }
    return false;
  }

  toggleSelectAll() {
    this.searchNodeText = '';
    // @ts-ignore
    this.$refs.searchNodeTextField.clearText();
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.selectedMultipleNodeIndexes = Array(this.excludeFirstNodes.length)
        .fill(null)
        .map((_, i) => i);
    } else {
      this.selectedMultipleNodeIndexes = [];
    }
    this.prepareMultipleNodeTagList();
  }

  toggleMultipleNodeSelected(node: any) {
    if (this.isSelectedMultipleNode(node)) {
      this.isAllSelected = false;
      let newList = _.cloneDeep(this.selectedMultipleNodeIndexes);
      _.remove(newList, (n: number) => {
        return n == _.findIndex(this.excludeFirstNodes, node);
      });
      this.selectedMultipleNodeIndexes = _.cloneDeep(newList);
    } else {
      this.selectedMultipleNodeIndexes.push(_.findIndex(this.excludeFirstNodes, node));
      this.selectedMultipleNodeIndexes.sort();
    }
    this.prepareMultipleNodeTagList();
  }

  prepareMultipleNodeTagList() {
    this.currentMultipleTagList = [];
    if (this.selectedMultipleNodeIndexes.length > 0) {
      let tagCounts: any = [];
      _.forEach(this.tags, (t: any) => {
        let tagCount: number = 0;
        let hasTag: boolean = true;
        let tagList: any = [];
        _.forEach(this.selectedMultipleNodeIndexes, (n: number) => {
          let foundTag = _.find(this.allTagList[n], { tag: t });
          if (!foundTag) {
            hasTag = false;
          } else {
            tagCount++;
            tagList.push(foundTag);
          }
        });
        if (hasTag) {
          this.currentMultipleTagList.push(tagList);
        }
        tagCounts.push(tagCount);
      });
      let diffTags = _.filter(tagCounts, (c: number) => {
        return c != this.selectedMultipleNodeIndexes.length;
      });
      this.diffTag = _.sum(diffTags);
      this.currentTagList = [];
      _.forEach(this.currentMultipleTagList, (tagList: any) => {
        let newTag: any = this.getNewTag();
        newTag.tag = tagList[0].tag;
        newTag.soundGroup =
          _.uniq(_.map(tagList, 'soundGroup.snd_grp_id')).length == 1
            ? tagList[0].soundGroup
            : 'มีหลายกลุ่มเสียง';
        newTag.fromDistance =
          _.uniq(_.map(tagList, 'fromDistance')).length == 1 ? tagList[0].fromDistance : '#';
        newTag.fromDistanceUnit =
          _.uniq(_.map(tagList, 'fromDistanceUnit.flag')).length == 1
            ? tagList[0].fromDistanceUnit
            : '#';
        newTag.fromDistanceType =
          _.uniq(_.map(tagList, 'fromDistanceType.flag')).length == 1
            ? tagList[0].fromDistanceType
            : '###';
        newTag.toDistance =
          _.uniq(_.map(tagList, 'toDistance')).length == 1 ? tagList[0].toDistance : '#';
        newTag.toDistanceUnit =
          _.uniq(_.map(tagList, 'toDistanceUnit.flag')).length == 1
            ? tagList[0].toDistanceUnit
            : '#';
        newTag.toDistanceType =
          _.uniq(_.map(tagList, 'toDistanceType.flag')).length == 1
            ? tagList[0].toDistanceType
            : '###';
        this.currentTagList.push(newTag);
      });
    }
  }

  isSelectedMultipleNode(node: any) {
    return this.selectedMultipleNodeIndexes.includes(_.findIndex(this.excludeFirstNodes, node));
  }

  loadNetworkArcs() {
    getNetworkArcs(this.opId, this.network, this.version.net_ver)
      .then(res => {
        this.networkArcs = res.data;
        this.loadNodes();
      })
      .catch(err => {})
      .finally(() => {});
  }

  allowedFromDistanceInput($event: any) {
    if (
      this.isDistanceUnitMetre(
        this.currentTagList[this.activeDistanceFromIndex].fromDistanceUnit.flag
      )
    ) {
      if (
        (!this.isDistanceTypeAfterDeparture(
          this.currentTagList[this.activeDistanceFromIndex].fromDistanceType.flag
        ) &&
          $event.charCode == 45) ||
        ($event.charCode >= 48 && $event.charCode <= 57)
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    } else {
      if (
        (!this.isDistanceTypeAfterDeparture(
          this.currentTagList[this.activeDistanceFromIndex].fromDistanceType.flag
        ) &&
          $event.charCode == 45) ||
        $event.charCode == 46 ||
        ($event.charCode >= 48 && $event.charCode <= 57)
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
      return true;
    }
  }

  allowedToDistanceInput($event: any) {
    if (
      this.isDistanceUnitMetre(this.currentTagList[this.activeDistanceToIndex].toDistanceUnit.flag)
    ) {
      if (
        (!this.isDistanceTypeAfterDeparture(
          this.currentTagList[this.activeDistanceToIndex].toDistanceType.flag
        ) &&
          $event.charCode == 45) ||
        ($event.charCode >= 48 && $event.charCode <= 57)
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    } else {
      if (
        (!this.isDistanceTypeAfterDeparture(
          this.currentTagList[this.activeDistanceToIndex].toDistanceType.flag
        ) &&
          $event.charCode == 45) ||
        $event.charCode == 46 ||
        ($event.charCode >= 48 && $event.charCode <= 57)
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
      return true;
    }
  }

  correctFromDistanceInput() {
    if (this.activeDistanceFromIndex != undefined) {
      if (
        !this.isDistanceUnitMetre(
          this.currentTagList[this.activeDistanceFromIndex].fromDistanceUnit.flag
        )
      ) {
        let value = parseFloat(this.currentTagList[this.activeDistanceFromIndex].fromDistance) || 0;
        const max = this.isMultipleNodeSelection
          ? 200.0
          : 100.0 + this.getExtraDistancePercent(this.selectedNodeIndex);
        const min = this.isMultipleNodeSelection
          ? -200.0
          : -this.getExtraDistancePercent(this.selectedNodeIndex);
        if (value > max) {
          value = max;
        }

        if (
          this.isDistanceTypeAfterDeparture(
            this.currentTagList[this.activeDistanceFromIndex].fromDistanceType.flag
          )
        ) {
          if (value < 0) {
            value = 0;
          }
        } else {
          if (value < min) {
            value = min;
          }
        }

        this.currentTagList[this.activeDistanceFromIndex].fromDistance = this.addZeroes(
          parseFloat(value.toFixed(2))
        );
      } else {
        let parsedValue =
          parseInt(this.currentTagList[this.activeDistanceFromIndex].fromDistance) || 0;
        const max = this.isMultipleNodeSelection
          ? 99999
          : this.distance + this.getExtraDistanceMetre(this.selectedNodeIndex);
        const min = this.isMultipleNodeSelection
          ? -99999
          : -this.getExtraDistanceMetre(this.selectedNodeIndex);
        if (parsedValue > max) {
          parsedValue = max;
        }

        if (
          this.isDistanceTypeAfterDeparture(
            this.currentTagList[this.activeDistanceFromIndex].fromDistanceType.flag
          )
        ) {
          if (parsedValue < 0) {
            parsedValue = 0;
          }
        } else {
          if (parsedValue < min) {
            parsedValue = min;
          }
        }
        this.currentTagList[this.activeDistanceFromIndex].fromDistance = parsedValue;
      }
    }
    if (this.isMultipleNodeSelection) {
      this.applyMultipleChanged(null, null);
      this.validateAllTags();
    } else {
      this.validateTags(this.activeTagIndex);
    }
  }

  correctToDistanceInput() {
    if (this.activeDistanceToIndex != undefined) {
      if (
        !this.isDistanceUnitMetre(
          this.currentTagList[this.activeDistanceToIndex].toDistanceUnit.flag
        )
      ) {
        let value = parseFloat(this.currentTagList[this.activeDistanceToIndex].toDistance) || 0;
        const max = this.isMultipleNodeSelection
          ? 200.0
          : 100.0 + this.getExtraDistancePercent(this.selectedNodeIndex);
        const min = this.isMultipleNodeSelection
          ? -200.0
          : -this.getExtraDistancePercent(this.selectedNodeIndex);
        if (value > max) {
          value = max;
        }

        if (
          this.isDistanceTypeAfterDeparture(
            this.currentTagList[this.activeDistanceToIndex].toDistanceType.flag
          )
        ) {
          if (value < 0) {
            value = 0;
          }
        } else {
          if (value < min) {
            value = min;
          }
        }

        this.currentTagList[this.activeDistanceToIndex].toDistance = this.addZeroes(
          parseFloat(value.toFixed(2))
        );
      } else {
        let parsedValue = parseInt(this.currentTagList[this.activeDistanceToIndex].toDistance) || 0;
        const max = this.isMultipleNodeSelection
          ? 99999
          : this.distance + this.getExtraDistanceMetre(this.selectedNodeIndex);
        const min = this.isMultipleNodeSelection
          ? -99999
          : -this.getExtraDistanceMetre(this.selectedNodeIndex);
        if (parsedValue > max) {
          parsedValue = max;
        }

        if (
          this.isDistanceTypeAfterDeparture(
            this.currentTagList[this.activeDistanceToIndex].toDistanceType.flag
          )
        ) {
          if (parsedValue < 0) {
            parsedValue = 0;
          }
        } else {
          if (parsedValue < min) {
            parsedValue = min;
          }
        }
        this.currentTagList[this.activeDistanceToIndex].toDistance = parsedValue;
      }
    }
    if (this.isMultipleNodeSelection) {
      this.applyMultipleChanged(null, null);
      this.validateAllTags();
    } else {
      this.validateTags(this.activeTagIndex);
    }
  }

  getFlooredFixed(v: number, d: number) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  addZeroes(num: number) {
    return num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
  }

  addNewTag(tag?: any) {
    this.currentTagList.push(this.getNewTag(tag));
    if (this.isMultipleNodeSelection) {
      _.forEach(this.selectedMultipleNodeIndexes, (index: number) => {
        if (this.allTagList[index].length == this.tags.length) {
          this.allTagList[index].splice(_.findIndex(this.allTagList[index], { tag: null }), 1);
        }
        this.allTagList[index].push(this.getNewTag(tag));
      });
    }
  }

  getNewTag(tag?: any) {
    return {
      tag: tag ? tag : null,
      tagError: null,
      soundGroup: null,
      soundGroupError: null,
      fromDistance: 0,
      fromDistanceUnit: this.distanceUnit[0],
      fromDistanceType: this.distanceType[0],
      fromError: null,
      toDistance: 0,
      toDistanceUnit: this.distanceUnit[0],
      toDistanceType: this.distanceType[0],
      toError: null,
      error: null,
      positiveCollapseError: null,
      negativeCollapseError: null,
    };
  }

  selectTag(tag: any) {
    if (this.isMultipleNodeSelection) {
      this.applyMultipleChanged(this.currentTagList[this.activeTagNameDropdownIndex].tag, tag);
      this.validateAllTags();
    }
    this.currentTagList[this.activeTagNameDropdownIndex].tag = tag;
    if (!this.isMultipleNodeSelection) {
      this.validateTags(this.activeTagIndex);
    }
    this.dismissDropdown();
  }

  selectSoundGroup(soundGroup: any) {
    this.preventDismissDropdown = false;
    this.currentTagList[this.activeSoundGroupDropdownIndex].soundGroup = soundGroup;
    if (this.isMultipleNodeSelection) {
      this.applyMultipleChanged(null, null);
      this.validateAllTags();
    } else {
      this.validateTags(this.activeTagIndex);
    }
    this.dismissDropdown();
  }

  selectFromDistanceUnit(distanceUnit: any) {
    if (
      this.currentTagList[this.activeDistanceFromUnitDropdownIndex].fromDistanceUnit.flag !=
      distanceUnit.flag
    ) {
      this.currentTagList[this.activeDistanceFromUnitDropdownIndex].fromDistanceUnit = distanceUnit;
      this.onTagDataSelected();
    } else {
      this.dismissDropdown();
    }
  }

  selectToDistanceUnit(distanceUnit: any) {
    if (
      this.currentTagList[this.activeDistanceToUnitDropdownIndex].toDistanceUnit.flag !=
      distanceUnit.flag
    ) {
      this.currentTagList[this.activeDistanceToUnitDropdownIndex].toDistanceUnit = distanceUnit;
      this.onTagDataSelected();
    } else {
      this.dismissDropdown();
    }
  }

  selectFromDistanceType(distanceType: any) {
    if (
      this.currentTagList[this.activeDistanceFromTypeDropdownIndex].fromDistanceType.flag !=
      distanceType.flag
    ) {
      this.currentTagList[this.activeDistanceFromTypeDropdownIndex].fromDistanceType = distanceType;
      this.onTagDataSelected();
    } else {
      this.dismissDropdown();
    }
  }

  selectToDistanceType(distanceType: any) {
    if (
      this.currentTagList[this.activeDistanceToTypeDropdownIndex].toDistanceType.flag !=
      distanceType
    ) {
      this.currentTagList[this.activeDistanceToTypeDropdownIndex].toDistanceType = distanceType;
      this.onTagDataSelected();
    } else {
      this.dismissDropdown();
    }
  }

  onTagDataSelected() {
    this.correctFromDistanceInput();
    this.correctToDistanceInput();
    this.dismissDropdown();
    if (this.isMultipleNodeSelection) {
      this.applyMultipleChanged(null, null);
    }
  }

  stepUpFromDistance(index: number) {
    if (!this.isDistanceUnitMetre(this.currentTagList[index].fromDistanceUnit.flag)) {
      let value = parseFloat(this.currentTagList[index].fromDistance) || 0;

      const max = this.isMultipleNodeSelection
        ? 200.0
        : 100.0 + this.getExtraDistancePercent(this.selectedNodeIndex);
      if (value < max) {
        value += 0.01;
      }
      this.currentTagList[index].fromDistance = value;
    } else {
      let parsedValue = parseInt(this.currentTagList[index].fromDistance) || 0;
      const max = this.isMultipleNodeSelection
        ? 99999
        : this.distance + this.getExtraDistanceMetre(this.selectedNodeIndex);
      if (parsedValue < max) {
        parsedValue++;
      }
      this.currentTagList[index].fromDistance = parsedValue;
    }
    this.activeDistanceFromIndex = index;
    this.activeTagIndex = index;
    this.correctFromDistanceInput();
  }

  stepDownFromDistance(index: number) {
    if (!this.isDistanceUnitMetre(this.currentTagList[index].fromDistanceUnit.flag)) {
      let value = parseFloat(this.currentTagList[index].fromDistance) || 0;

      let min = this.isMultipleNodeSelection
        ? -200
        : -this.getExtraDistancePercent(this.selectedNodeIndex);
      if (value > min) {
        value -= 0.01;
      }
      this.currentTagList[index].fromDistance = value;
    } else {
      let parsedValue = parseInt(this.currentTagList[index].fromDistance) || 0;
      let min = this.isMultipleNodeSelection
        ? -99999
        : -this.getExtraDistanceMetre(this.selectedNodeIndex);
      if (parsedValue > min) {
        parsedValue--;
      }
      this.currentTagList[index].fromDistance = parsedValue;
    }
    this.activeDistanceFromIndex = index;
    this.activeTagIndex = index;
    this.correctFromDistanceInput();
  }

  stepUpToDistance(index: number) {
    if (!this.isDistanceUnitMetre(this.currentTagList[index].toDistanceUnit.flag)) {
      let value = parseFloat(this.currentTagList[index].toDistance) || 0;
      const max = this.isMultipleNodeSelection
        ? 200.0
        : 100.0 + this.getExtraDistancePercent(this.selectedNodeIndex);
      if (value < max) {
        value += 0.01;
      }
      this.currentTagList[index].toDistance = value;
    } else {
      let parsedValue = parseInt(this.currentTagList[index].toDistance) || 0;
      const max = this.isMultipleNodeSelection
        ? 99999
        : this.distance + this.getExtraDistanceMetre(this.selectedNodeIndex);
      if (parsedValue < max) {
        parsedValue++;
      }
      this.currentTagList[index].toDistance = parsedValue;
    }
    this.activeDistanceToIndex = index;
    this.activeTagIndex = index;
    this.correctToDistanceInput();
  }

  stepDownToDistance(index: number) {
    if (!this.isDistanceUnitMetre(this.currentTagList[index].toDistanceUnit.flag)) {
      let value = parseFloat(this.currentTagList[index].toDistance) || 0;

      let min = this.isMultipleNodeSelection
        ? -200
        : -this.getExtraDistancePercent(this.selectedNodeIndex);
      if (value > min) {
        value -= 0.01;
      }
      this.currentTagList[index].toDistance = value;
    } else {
      let parsedValue = parseInt(this.currentTagList[index].toDistance) || 0;

      let min = this.isMultipleNodeSelection
        ? -99999
        : -this.getExtraDistanceMetre(this.selectedNodeIndex);
      if (parsedValue > min) {
        parsedValue--;
      }
      this.currentTagList[index].toDistance = parsedValue;
    }
    this.activeDistanceToIndex = index;
    this.activeTagIndex = index;
    this.correctToDistanceInput();
  }

  deleteTag() {
    if (this.activeTagIndex != null) {
      if (this.isMultipleNodeSelection) {
        const tag = this.currentTagList[this.activeTagIndex].tag;
        if (tag) {
          _.forEach(this.selectedMultipleNodeIndexes, (n: number) => {
            this.allTagList[n] = _.filter(this.allTagList[n], (tagData: any) => {
              return !tagData.tag || tagData.tag.flag != tag.flag;
            });
          });
        }
      }
      this.currentTagList.splice(this.activeTagIndex, 1);
      this.activeTagIndex = null;
    }
  }

  isDistanceUnitMetre(flag: any) {
    return flag == this.distanceUnit[0].flag;
  }

  isDistanceTypeAfterDeparture(flag: any) {
    return flag == this.distanceType[0].flag;
  }

  validateTags(index: number | null, fromTag?: any, nodeIndex?: number) {
    if (index != null) {
      let tagData = _.cloneDeep(fromTag ? fromTag : this.currentTagList[index]);
      tagData.error = null;
      tagData.tagError = null;
      tagData.soundGroupError = null;
      tagData.toError = null;
      tagData.fromError = null;

      const processedNodeIndex = nodeIndex ? nodeIndex : this.selectedNodeIndex;

      tagData.positiveCollapseError = this.positiveCollapseMessage(tagData);
      tagData.negativeCollapseError = this.negativeCollapseMessage(tagData);

      let distance = parseInt(this.getDistance(processedNodeIndex));
      distance = distance + this.getExtraDistanceMetre(processedNodeIndex);

      let fromDistance = tagData.fromDistance;
      let toDistance = tagData.toDistance;

      if (this.isDistanceUnitMetre(tagData.fromDistanceUnit.flag)) {
        fromDistance = (fromDistance / distance) * 100;
      }

      if (this.isDistanceUnitMetre(tagData.toDistanceUnit.flag)) {
        toDistance = (toDistance / distance) * 100;
      }

      fromDistance = parseFloat(fromDistance);
      toDistance = parseFloat(toDistance);

      if (!tagData.tag) {
        tagData.tagError = 'กรุณาเลือกประเภท';
      }
      if (!tagData.soundGroup) {
        tagData.soundGroupError = 'กรุณาเลือกกลุ่มเสียง';
      }

      if (
        fromDistance > 100.0 + this.getExtraDistancePercent(processedNodeIndex) ||
        fromDistance < -this.getExtraDistancePercent(processedNodeIndex)
      ) {
        tagData.fromError = 'ระยะที่ตั้งอยู่นอกขอบเขตที่อนุญาต กรุณาปรับระยะ';
      }
      if (
        toDistance > 100.0 + this.getExtraDistancePercent(processedNodeIndex) ||
        toDistance < -this.getExtraDistancePercent(processedNodeIndex)
      ) {
        tagData.toError = 'ระยะที่ตั้งอยู่นอกขอบเขตที่อนุญาต กรุณาปรับระยะ';
      }

      if (fromDistance == toDistance) {
        tagData.error = 'ตำแหน่งปลายระยะต้องอยู่ถัดจากต้นระยะ';
      }

      //หลังออก และ หลังออก
      if (
        this.isDistanceTypeAfterDeparture(tagData.fromDistanceType.flag) &&
        this.isDistanceTypeAfterDeparture(tagData.toDistanceType.flag)
      ) {
        if (fromDistance > toDistance) {
          tagData.error = 'ตำแหน่งต้นระยะ/ปลายระยะถูกกำหนดสลับกัน';
        }
      } else if (
        //ก่อนถึง และ ก่อนถึง
        !this.isDistanceTypeAfterDeparture(tagData.fromDistanceType.flag) &&
        !this.isDistanceTypeAfterDeparture(tagData.toDistanceType.flag)
      ) {
        if (fromDistance < toDistance) {
          tagData.error = 'ตำแหน่งต้นระยะ/ปลายระยะถูกกำหนดสลับกัน';
        }
      } else if (
        //หลังออก และ ก่อนถึง
        this.isDistanceTypeAfterDeparture(tagData.fromDistanceType.flag) &&
        !this.isDistanceTypeAfterDeparture(tagData.toDistanceType.flag)
      ) {
        if (100 - fromDistance < toDistance) {
          tagData.error = 'ตำแหน่งต้นระยะ/ปลายระยะถูกกำหนดสลับกัน';
        }
      } else if (
        //ก่อนถึง และ หลังออก
        !this.isDistanceTypeAfterDeparture(tagData.fromDistanceType.flag) &&
        this.isDistanceTypeAfterDeparture(tagData.toDistanceType.flag)
      ) {
        if (100 - fromDistance > toDistance) {
          tagData.error = 'ตำแหน่งต้นระยะ/ปลายระยะถูกกำหนดสลับกัน';
        }
      }
      if (fromTag) {
        return tagData;
      } else {
        this.$set(this.currentTagList, index, tagData);
      }
    }
  }

  validateAllTags() {
    let newAllTagList: any = [];
    _.each(this.allTagList, (tagList: any, nodeIndex: number) => {
      let newTagList: any = [];
      _.each(tagList, (t: any, index: number) => {
        newTagList.push(this.validateTags(index, t, nodeIndex));
      });
      newAllTagList.push(newTagList);
    });
    this.allTagList = _.cloneDeep(newAllTagList);
  }

  hasError() {
    let hasError = false;
    _.forEach(this.excludeFirstNodes, (node: any) => {
      if (this.hasErrorInNode(node)) {
        hasError = true;
        return false;
      }
    });
    return hasError;
  }

  hasErrorInNode(node: any) {
    const index = _.findIndex(this.excludeFirstNodes, node);
    const tagList = this.allTagList[index];
    let hasError = false;
    _.forEach(tagList, (t: any) => {
      if (t.tagError || t.soundGroupError || t.fromError || t.toError || t.error) {
        hasError = true;
        return false;
      }
    });

    return hasError;
  }

  applyMultipleChanged(previousTag: any | null, newTag: any) {
    let tagFlag = previousTag
      ? previousTag.flag
      : _.get(this.currentTagList, `[${this.activeTagIndex}].tag.flag`, null);
    _.forEach(this.selectedMultipleNodeIndexes, (n: number) => {
      const index = tagFlag
        ? _.findIndex(this.allTagList[n], { tag: { flag: tagFlag } })
        : _.findIndex(this.allTagList[n], { tag: null });
      if (newTag) {
        this.allTagList[n][index].tag = newTag;
      }

      const soundGroup = this.currentTagList[this.activeTagIndex].soundGroup;
      if (typeof soundGroup !== 'string') {
        this.allTagList[n][index].soundGroup = soundGroup;
      }
      const fromDistance = this.currentTagList[this.activeTagIndex].fromDistance;
      if (fromDistance !== '#') {
        this.allTagList[n][index].fromDistance = fromDistance;
      }
      const fromDistanceUnit = this.currentTagList[this.activeTagIndex].fromDistanceUnit;
      if (typeof fromDistanceUnit !== 'string') {
        this.allTagList[n][index].fromDistanceUnit = fromDistanceUnit;
      }
      const fromDistanceType = this.currentTagList[this.activeTagIndex].fromDistanceType;
      if (typeof fromDistanceType !== 'string') {
        this.allTagList[n][index].fromDistanceType = fromDistanceType;
      }
      const toDistance = this.currentTagList[this.activeTagIndex].toDistance;
      if (toDistance !== '#') {
        this.allTagList[n][index].toDistance = toDistance;
      }
      const toDistanceUnit = this.currentTagList[this.activeTagIndex].toDistanceUnit;
      if (typeof toDistanceUnit !== 'string') {
        this.allTagList[n][index].toDistanceUnit = toDistanceUnit;
      }
      const toDistanceType = this.currentTagList[this.activeTagIndex].toDistanceType;
      if (typeof toDistanceType !== 'string') {
        this.allTagList[n][index].toDistanceType = toDistanceType;
      }
    });
  }

  prepareDataAfterValidated() {
    if (!this.isMultipleNodeSelection) {
      this.currentTagList = this.allTagList[this.selectedNodeIndex];
    }

    if (!this.isMultipleNodeSelection) {
      this.currentTagList = this.allTagList[this.selectedNodeIndex];
    }
  }

  onClickTagDropdown(index: number) {
    this.dismissDropdown();
    this.activeTagNameDropdownIndex = index;
    this.activeTagIndex = index;
  }

  onClickSoundGroupDropdown(index: number) {
    this.dismissDropdown();
    this.activeSoundGroupDropdownIndex = index;
    this.activeTagIndex = index;
  }

  onClickDistanceFromUnitDropdown(index: number) {
    this.dismissDropdown();
    this.activeDistanceFromUnitDropdownIndex = index;
    this.activeDistanceFromIndex = index;
    this.activeTagIndex = index;
  }

  onClickDistanceToUnitDropdown(index: number) {
    this.dismissDropdown();
    this.activeDistanceToUnitDropdownIndex = index;
    this.activeDistanceToIndex = index;
    this.activeTagIndex = index;
  }

  onClickDistanceFromTypeDropdown(index: number) {
    this.dismissDropdown();
    this.activeDistanceFromTypeDropdownIndex = index;
    this.activeTagIndex = index;
  }

  onClickDistanceToTypeDropdown(index: number) {
    this.dismissDropdown();
    this.activeDistanceToTypeDropdownIndex = index;
    this.activeTagIndex = index;
  }

  onClickDeleteTagButton(index: number) {
    this.isDeletingTag = true;
    this.activeTagIndex = index;
  }

  onClickMultipleSelectionButton() {
    this.isMultipleNodeSelection = !this.isMultipleNodeSelection;
    if (this.isMultipleNodeSelection) {
      this.selectedNode = null;
      this.selectedNodeIndex = 0;
    } else {
      this.selectedMultipleNodeIndexes = [];
    }
  }

  onClickSaveButton() {
    this.validateAllTags();
    this.prepareDataAfterValidated();

    if (!this.hasError()) {
      this.isConfirmSavePopupVisible = true;
    }
  }

  onClickCloseButton() {
    if (this.isJustFinishedSave || _.isEqual(this.allTagList, this.previousAllTagList)) {
      this.close();
    } else {
      this.validateAllTags();
      this.prepareDataAfterValidated();
      this.isDiscardPopupVisible = true;
    }
  }

  onSearchNodeTextChanged(text: string) {
    this.searchNodeText = text;
  }

  onSearchSoundGroupTextChanged(text: string) {
    this.searchSoundGroupText = text;
  }

  onConfirmSave() {
    this.isConfirmSavePopupVisible = false;

    let data: any = [];
    _.each(this.allTagList, (nodeTag: any, index: number) => {
      data.push({
        seq: index + 2,
        sound_dist_ref: _.map(nodeTag, (tagData: any) => {
          return [parseFloat(tagData.fromDistance), parseFloat(tagData.toDistance)];
        }),
        sound_dist_ref_flag: _.map(nodeTag, (tagData: any) => {
          return [
            tagData.fromDistanceUnit.flag + tagData.fromDistanceType.flag,
            tagData.toDistanceUnit.flag + tagData.toDistanceType.flag,
          ];
        }),
        sound_flag: _.map(nodeTag, (tagData: any) => {
          return tagData.tag.flag;
        }),
        sound_grp: _.map(nodeTag, (tagData: any) => {
          return tagData.soundGroup.snd_grp_id;
        }),
        sound_drv_pos: Array(nodeTag.length)
          .fill(null)
          .map((_, i) => i + 1),
      });
    });

    data = _.filter(data, (obj: any) => {
      return obj.sound_dist_ref.length > 0;
    });

    if (data.length > 0) {
      this.putNetworkArcSounds(data);
    } else {
      this.deleteNetworkArcSounds();
    }
  }

  dismissDropdown() {
    if (!this.preventDismissDropdown) {
      this.activeTagNameDropdownIndex = null;
      this.activeSoundGroupDropdownIndex = null;
      this.activeDistanceFromIndex = null;
      this.activeDistanceToIndex = null;
      this.activeDistanceFromUnitDropdownIndex = null;
      this.activeDistanceToUnitDropdownIndex = null;
      this.activeDistanceFromTypeDropdownIndex = null;
      this.activeDistanceToTypeDropdownIndex = null;
    } else {
      this.preventDismissDropdown = false;
    }
  }

  close() {
    this.$emit('onClose');
  }

  mounted() {
    this.loadNetworkArcs();
    window.addEventListener('resize', this.setGraphicLines);
  }

  destroyed() {
    window.removeEventListener('resize', this.setGraphicLines);
  }
}


import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import CloseButton from '@/components/CloseButton.vue';

@Component({
  components: {
    CloseButton,
  },
})
export default class DiscardPopup extends Vue {
  @Prop(String) readonly message!: string;
  @Prop() readonly canSave!: boolean;
  close() {
    this.$emit('onClose');
  }
  save() {
    this.$emit('onSave');
    this.$emit('onClose');
  }
  discard() {
    this.$emit('onDiscard');
  }
}

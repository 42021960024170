
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({})
export default class CommonPopup extends Vue {
  @Prop(String) readonly message!: string;
  close() {
    this.$emit('onClose');
  }
}

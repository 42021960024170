import { render, staticRenderFns } from "./DeleteTagPopup.vue?vue&type=template&id=65ad2708&scoped=true"
import script from "./DeleteTagPopup.vue?vue&type=script&lang=ts"
export * from "./DeleteTagPopup.vue?vue&type=script&lang=ts"
import style0 from "./DeleteTagPopup.vue?vue&type=style&index=0&id=65ad2708&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ad2708",
  null
  
)

export default component.exports

import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
// @ts-ignore
import _ from 'lodash-es';
// @ts-ignore
import moment from 'moment';
import SearchTextField from '@/components/form/SearchTextField.vue';
import CreateSoundGroupPopup from '@/components/sound-editor/CreateSoundGroupPopup.vue';
import DeleteSoundGroupPopup from '@/components/sound-editor/DeleteSoundGroupPopup.vue';
import EditSoundGroupPopup from '@/components/sound-editor/EditSoundGroupPopup.vue';
import { formattedDuration } from '@/utils/time';

@Component({
  components: {
    SearchTextField,
    CreateSoundGroupPopup,
    DeleteSoundGroupPopup,
    EditSoundGroupPopup,
  },
})
export default class SoundGroup extends Vue {
  @State soundGroups!: SoundGroup[];
  @State sounds!: any[];

  @Action loadSoundGroups!: () => Promise<void>;

  isEditPopoverVisible: boolean = false;
  isEditPopupVisible: boolean = false;
  isCreateSoundGroupPopupVisible: boolean = false;
  isDeletePopupVisible: boolean = false;

  searchText: string = '';
  selectedSoundGroup: any = null;
  isFreshCreate: boolean = true;
  playingSound: any | null = null;
  playingSoundIndex: number = -1;
  isPlayingAllSound: boolean = false;
  delayTimeout: any = null;

  get soundGroupList(): any | null {
    if (this.soundGroups) {
      const soundGroups = _.filter(this.soundGroups, (sg: any) => {
        return (sg.name || '').toLowerCase().includes(this.searchText.toLowerCase());
      });
      return soundGroups;
    }
    return null;
  }

  isSelectedSoundGroup(soundGroup: any): boolean {
    if (this.selectedSoundGroup) {
      return this.selectedSoundGroup.snd_grp_id == soundGroup.snd_grp_id;
    }
    return false;
  }

  getDelayLabel(delay: number): string {
    return moment.utc(delay || 0).format('mm:ss.S');
  }

  getSoundName(id: number): any {
    const sound = _.find(this.sounds, (s: any) => {
      return s.snd_id == id;
    });
    if (sound) {
      return sound.name;
    }
  }

  getSoundDuration(id: number) {
    const sound = _.find(this.sounds, (s: any) => {
      return s.snd_id == id;
    });
    if (sound) {
      return formattedDuration(sound.duration * 1000);
    }
    return null;
  }

  selectSoundGroup(soundGroup: any) {
    if (!this.selectedSoundGroup || this.selectedSoundGroup.snd_grp_id != soundGroup.snd_grp_id) {
      this.selectedSoundGroup = soundGroup;
    }
  }

  playSounds() {
    this.stopSound();
    const delays = _.get(this.selectedSoundGroup, 'sound_delay_ms', []);

    if (this.playingSoundIndex < 0) {
      this.playingSoundIndex = 0;
    }
    if (delays.length > 0 && this.playingSoundIndex < delays.length - 1) {
      this.isPlayingAllSound = true;
      this.delayTimeout = setTimeout(() => {
        this.playSoundById(
          this.selectedSoundGroup['sounds'][this.playingSoundIndex],
          this.playingSoundIndex
        );
      }, delays[this.playingSoundIndex]);
    } else {
      this.stopSounds();
    }
  }

  stopSounds() {
    this.stopSound();
    clearTimeout(this.delayTimeout);
    this.delayTimeout = null;
    this.playingSoundIndex = -1;
    this.isPlayingAllSound = false;
  }

  playSoundById(id: any, index: number) {
    const sound = _.find(this.sounds, (s: any) => {
      return s.snd_id == id;
    });
    if (sound) {
      this.playSound(sound);
      this.playingSoundIndex = index;
    } else {
      if (this.isPlayingAllSound) {
        this.playingSoundIndex++;
        this.playSounds();
      }
    }
  }

  playSound(sound: any) {
    this.stopSound();
    if (sound.snd_url) {
      let audio = new Audio(sound.snd_url);
      this.playingSound = {
        id: sound.snd_id,
        audio: audio,
      };
      audio.play();
      audio.onended = () => {
        this.playingSound = null;

        if (this.isPlayingAllSound) {
          this.playingSoundIndex++;
          this.playSounds();
        }
      };
    }
  }

  stopSound() {
    if (this.playingSound) {
      this.playingSound.audio.pause();
      this.playingSound = null;
      if (!this.isPlayingAllSound) {
        this.playingSoundIndex = -1;
        this.isPlayingAllSound = false;
      }
    }
  }

  onClickCreateButton() {
    this.stopSounds();
    this.isFreshCreate = true;
    this.isCreateSoundGroupPopupVisible = !this.isCreateSoundGroupPopupVisible;
  }

  onClickEditButton() {
    this.stopSounds();
    this.isEditPopoverVisible = true;
  }

  onClickEditSoundGroupButton() {
    this.isEditPopupVisible = true;
    this.isEditPopoverVisible = false;
  }

  onClickCreateWithExistingSoundGroupButton() {
    this.stopSounds();
    this.isFreshCreate = false;
    this.isCreateSoundGroupPopupVisible = !this.isCreateSoundGroupPopupVisible;
  }

  onClickDeleteButton() {
    this.stopSounds();
    this.isDeletePopupVisible = true;
  }

  onSearchTextChanged(text: string) {
    this.searchText = text;
  }

  dismissEditPopover() {
    this.isEditPopoverVisible = false;
  }

  loadData() {
    this.selectedSoundGroup = null;
    this.searchText = '';
    // @ts-ignore
    this.$refs.searchTextField.clearText();
    this.loadSoundGroups();
  }
}

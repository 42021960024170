import { render, staticRenderFns } from "./DeleteSoundGroupPopup.vue?vue&type=template&id=0995fe03&scoped=true"
import script from "./DeleteSoundGroupPopup.vue?vue&type=script&lang=ts"
export * from "./DeleteSoundGroupPopup.vue?vue&type=script&lang=ts"
import style0 from "./DeleteSoundGroupPopup.vue?vue&type=style&index=0&id=0995fe03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0995fe03",
  null
  
)

export default component.exports

import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({})
export default class AssignSoundConfirmPopup extends Vue {
  close() {
    this.$emit('onClose');
  }

  confirm() {
    this.$emit('onConfirm');
  }
}

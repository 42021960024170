
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';

import { putSoundGroup } from '@/services/axios';

@Component({
  components: {
    CloseButton,
    TextField,
  },
})
export default class EditSoundGroupPopup extends Vue {
  @State opId!: number;

  @Prop() readonly soundGroup: any;

  soundGroupName: string = '';
  isUpdating: boolean = false;
  isError: boolean = false;

  onSoundGroupNameChanged(name: string) {
    this.soundGroupName = name;
    this.isError = false;
  }

  close() {
    this.$emit('onClose');
  }

  updateSoundGroup(): void {
    if (!this.soundGroupName) {
      this.isError = true;
      return;
    }
    this.isUpdating = true;
    putSoundGroup(this.opId, this.soundGroup.snd_grp_id, { name: this.soundGroupName })
      .then(() => {
        this.$emit('onSoundGroupUpdated');
        this.close();
      })
      .catch(err => {})
      .finally(() => {
        this.isUpdating = false;
      });
  }

  mounted() {
    this.soundGroupName = this.soundGroup.name;
  }
}

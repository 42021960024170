
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { deleteSoundGroup } from '@/services/axios';

@Component({})
export default class DeleteSoundUploadPopup extends Vue {
  @State opId!: number;

  @Prop() readonly soundGroup: any;

  isDeleting: Boolean = false;

  close() {
    this.$emit('onClose');
  }

  deleteSoundGroup(): void {
    this.isDeleting = true;
    deleteSoundGroup(this.soundGroup.op_id, this.soundGroup.snd_grp_id)
      .then(() => {
        this.$emit('onSoundGroupDeleted');
        this.close();
      })
      .catch(err => {})
      .finally(() => {
        this.isDeleting = false;
      });
  }
}

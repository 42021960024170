
import _ from 'lodash';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { deleteSound, getSoundUsage } from '@/services/axios';

@Component({})
export default class DeleteSoundUploadPopup extends Vue {
  @State opId!: number;

  @Prop() readonly sounds: any;

  isProcessing: Boolean = false;

  close() {
    this.$emit('onClose');
  }

  checkSoundUsage() {
    this.isProcessing = true;
    getSoundUsage(this.opId, { snd_lst: _.map(this.sounds, 'snd_id') })
      .then((res: any) => {
        // console.log('usage', res.data);
      })
      .catch(err => {})
      .finally(() => {
        this.isProcessing = false;
      });
  }

  deleteSound() {
    this.isProcessing = true;
    deleteSound(this.opId, { snd_lst: _.map(this.sounds, 'snd_id') })
      .then(() => {
        this.$emit('onSoundDeleted', this.sounds);
      })
      .catch(err => {})
      .finally(() => {
        this.isProcessing = false;
      });
  }
}


// @ts-ignore
import _ from 'lodash-es';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import CloseButton from '@/components/CloseButton.vue';

import { postSound } from '@/services/axios';

@Component({
  components: {
    CloseButton,
  },
})
export default class SoundUploadPopup extends Vue {
  @State opId!: number;

  browsedFiles: any = [];
  isUploading: Boolean = false;
  progresses: any = [];
  currentUploadIndex: any = 0;
  retryCount: number = 0;
  maxRetryCount: number = 4;

  get browsedFileLabel(): string {
    if (this.browsedFiles.length > 0) {
      if (this.browsedFiles.length == 1) {
        return this.browsedFiles[0].name;
      } else {
        return `${this.browsedFiles.length} ไฟล์`;
      }
    }
    return '';
  }

  get totalProgressLabel(): string {
    if (this.isUploading) {
      return `กำลังอัปโหลด… (${Math.floor(_.sum(this.progresses) / this.progresses.length)}%)`;
    }
    return '';
  }

  close() {
    this.$emit('onClose');
  }

  onFilesSelected(event: any) {
    let fileData = event.target.files;

    this.browsedFiles = null;
    this.browsedFiles = fileData;
    this.progresses = _.times(this.browsedFiles.length, _.constant(0));
    this.currentUploadIndex = 0;
  }

  upload() {
    if (this.browsedFiles.length > 0) {
      this.isUploading = true;

      const progress = (index: number) => (progress: any) => {
        let percentage = Math.floor((progress.loaded * 100) / progress.total);
        let nextProgresses = [...this.progresses];
        nextProgresses[index] = percentage;
        this.progresses = nextProgresses;
      };

      var config = {
        onUploadProgress: progress(this.currentUploadIndex),
      };

      const file = this.browsedFiles[this.currentUploadIndex];

      let audio = new Audio();
      audio.onloadedmetadata = () => {
        const formData = new FormData();
        formData.append('file', file);
        let duration = audio.duration - 1;
        if (duration < 0) {
          duration = 0;
        }
        formData.append('duration', duration.toFixed());

        postSound(this.opId, formData, config)
          .then(() => {
            this.currentUploadIndex++;
            this.retryCount = 0;
            if (this.currentUploadIndex < this.browsedFiles.length) {
              this.upload();
            } else {
              this.$emit('onUploaded');
            }
          })
          .catch(err => {
            if (this.retryCount < this.maxRetryCount) {
              this.retryCount++;
              setTimeout(() => {
                this.upload();
              }, 4000);
            } else {
              this.isUploading = false;
              this.retryCount = 0;
            }
          });
      };

      const objectUrl = URL.createObjectURL(file);
      audio.src = objectUrl;
    }
  }
}


import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
//@ts-ignore
import _ from 'lodash-es';
import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';
import SearchTextField from '@/components/form/SearchTextField.vue';
import TimeTextField from '@/components/form/TimeTextField.vue';
import { postSoundGroup } from '@/services/axios';
import { formattedDuration } from '@/utils/time';

@Component({
  components: {
    CloseButton,
    TextField,
    SearchTextField,
    TimeTextField,
  },
})
export default class CreateSoundGroupPopup extends Vue {
  @State opId!: number;
  @State sounds!: any[];

  @Prop() readonly selectedSoundGroup!: any;
  @Prop() readonly isFreshCreate!: boolean;

  soundGroupName: string = '';
  searchText: string = '';
  isError: boolean = false;
  isCreating: boolean = false;

  selectedSounds: any[] = [];
  delays: number[] = [];
  lastDelay: number = 0;
  playingSound: any | null = null;
  playingSoundIndex: number = -1;

  get soundList(): any | null {
    if (this.sounds) {
      const sounds = _.filter(this.sounds, (s: any) => {
        return (s.name || '').toLowerCase().includes(this.searchText.toLowerCase());
      });
      return sounds;
    }
    return null;
  }

  getSoundName(id: number): any {
    const sound = _.find(this.sounds, (s: any) => {
      return s.snd_id == id;
    });
    if (sound) {
      return sound.name;
    }
    return '';
  }

  getSoundDurationWithSound(sound: any) {
    if (sound) {
      return formattedDuration(sound.duration * 1000);
    }
    return null;
  }

  addSound(sound: any) {
    this.selectedSounds.push(sound);

    if (this.delays.length === 0) {
      this.delays.push(500);
      this.delays.push(500);
    } else {
      this.delays.splice(this.delays.length - 1, 0, 500);
    }
  }

  removeSound(index: number) {
    this.stopSound();
    this.selectedSounds = this.selectedSounds.filter((s, i) => i !== index);
    this.delays = this.delays.filter((d, i) => i !== index);

    if (this.selectedSounds.length === 0) {
      this.delays = [];
    }
  }

  deselectSound(index: number): void {}

  playSoundById(id: any, index: number) {
    const sound = _.find(this.sounds, (s: any) => {
      return s.snd_id == id;
    });
    if (sound) {
      this.playSound(sound);
      this.playingSoundIndex = index;
    }
  }

  playSound(sound: any) {
    this.stopSound();
    if (sound.snd_url) {
      let audio = new Audio(sound.snd_url);
      this.playingSound = {
        id: sound.snd_id,
        audio: audio,
      };
      audio.play();
      audio.onended = () => {
        this.playingSound = null;
      };
    }
  }

  stopSound() {
    if (this.playingSound) {
      this.playingSound.audio.pause();
      this.playingSound = null;
      this.playingSoundIndex = -1;
    }
  }

  moveSound(index: number, diff: number): void {
    const temp = this.selectedSounds[index];
    this.$set(this.selectedSounds, index, this.selectedSounds[index + diff]);
    this.$set(this.selectedSounds, index + diff, temp);
  }

  createSoundGroup() {
    this.stopSound();
    if (!this.soundGroupName) {
      this.isError = true;
      return;
    }
    this.isCreating = true;
    let preparedSoundGroup = {
      name: this.soundGroupName,
      is_act: true,
      sound_flag: 0,
    };

    if (this.selectedSounds.length > 0 && this.delays.length > 0) {
      let soundComponents = {
        sounds: _.map(this.selectedSounds, 'snd_id'),
        sound_delay_ms: this.delays,
      };
      Object.assign(preparedSoundGroup, soundComponents);
    }

    postSoundGroup(this.opId, preparedSoundGroup)
      .then(() => {
        this.$emit('onSoundGroupCreated');
        this.close();
      })
      .catch(err => {})
      .finally(() => {
        this.isCreating = false;
      });
  }

  close() {
    this.stopSound();
    this.$emit('onClose');
  }

  onSoundGroupNameChanged(name: string) {
    this.soundGroupName = name;
    this.isError = false;
  }

  onSearchTextChanged(text: string) {
    this.searchText = text;
  }

  onTimeTextFieldChange(result: any) {
    this.$set(this.delays, result.elementIndex, result.totalMs);
  }

  mounted() {
    if (!this.isFreshCreate) {
      this.selectedSounds = _.map(this.selectedSoundGroup.sounds, (snd_id: number) => {
        return _.find(this.sounds, (s: any) => snd_id === s.snd_id);
      });
      this.delays = _.cloneDeep(this.selectedSoundGroup.sound_delay_ms);
    }
  }
}


import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

// @ts-ignore
import _ from 'lodash-es';

import CloseButton from '@/components/CloseButton.vue';
import { putSoundGroup } from '@/services/axios';
import SearchTextField from '@/components/form/SearchTextField.vue';

@Component({
  components: {
    CloseButton,
    SearchTextField,
  },
})
export default class AssignGlobalSoundGroupPopup extends Vue {
  @State opId!: number;
  @State soundGroups!: any[];

  isAssigning: boolean = false;
  isSoundGroupListVisible: boolean = false;
  selectedSoundGroup: any = null;
  searchText: string = '';

  get availableSoundGroups() {
    if (this.soundGroups) {
      return _.filter(this.soundGroups, (sg: any) => {
        const flags = sg.sound_flag
          .toString(2)
          .split('')
          .reverse()
          .map((flag: any) => parseInt(flag, 2));
        return !flags[0];
      });
    }
    return null;
  }

  get filteredSoundGroups() {
    if (this.searchText) {
      let list = _.filter(this.availableSoundGroups, (sg: any) => {
        return sg.name && sg.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
      return list;
    } else {
      return this.availableSoundGroups;
    }
  }

  get canSave() {
    if (this.selectedSoundGroup) {
      if (_.isEqual(this.selectedSoundGroup.name, this.searchText)) {
        return true;
      }
    }
    return false;
  }

  dismissSoundGroupList() {
    this.isSoundGroupListVisible = false;
    if (this.selectedSoundGroup) {
      // @ts-ignore
      this.$refs.searchTextField.setText(this.selectedSoundGroup.name);
    }
  }

  selectSoundGroup(sg: any) {
    // @ts-ignore
    this.$refs.searchTextField.setText(sg.name);
    this.selectedSoundGroup = sg;
    this.dismissSoundGroupList();
  }

  isSelectedSoundGroup(sg: any) {
    if (this.selectedSoundGroup) {
      return this.selectedSoundGroup.snd_grp_id == sg.snd_grp_id;
    }
    return false;
  }

  assignSoundGroup() {
    this.isAssigning = true;
    putSoundGroup(this.opId, this.selectedSoundGroup.snd_grp_id, {
      name: this.selectedSoundGroup.name,
      sound_flag: 1,
    })
      .then(() => {
        this.$emit('onSoundGroupUpdated');
        this.close();
      })
      .catch(err => {})
      .finally(() => {
        this.isAssigning = false;
      });
  }

  onSearchTextChanged(text: string) {
    this.searchText = text;
  }

  onSearchTextFieldFocus() {
    this.isSoundGroupListVisible = true;
  }

  close() {
    this.$emit('onClose');
  }
}

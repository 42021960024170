
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

// @ts-ignore
import _ from 'lodash-es';

import SearchTextField from '@/components/form/SearchTextField.vue';
import AssignGlobalSoundGroupPopup from '@/components/sound-editor/AssignGlobalSoundGroupPopup.vue';
import AssignAutomaticSoundPopup from '@/components/sound-editor/AssignAutomaticSoundPopup.vue';
import { putSoundGroup, getNetworkVersions, getNetworkCurrentVersion } from '@/services/axios';

@Component({
  components: {
    SearchTextField,
    AssignGlobalSoundGroupPopup,
    AssignAutomaticSoundPopup,
  },
})
export default class AssignSound extends Vue {
  @State soundGroups!: any[];
  @State systems!: any;
  @State networks!: any;
  @State opId!: number;
  @State sounds!: any[];
  @State currentSystem!: any;

  @Action loadNetworks!: () => any;
  @Action loadSoundGroups!: () => any;

  @Watch('currentSystem', { deep: true })
  onCurrentSystemChanged() {
    this.stopSounds();
    this.loadNetworks();
  }

  selectedSoundGroup: any = null;
  playingSound: any | null = null;
  playingSoundIndex: number = -1;
  isPlayingAllSound: boolean = false;
  delayTimeout: any = null;

  isAssignGlobalSoundGroupPopupVisible: boolean = false;
  isAssignAutomaticSoundPopupVisible: boolean = false;

  searchNetworkText: string = '';
  selectedNetwork: any = null;
  networkVersions: any = [];
  currentNetworkVersion: any = null;
  selectedVersion: any = null;
  isRemovingGlobalSoundGroup: boolean = false;

  @Watch('networks', { deep: true })
  onNetworksChanged() {}

  get globalSoundGroups(): any {
    if (this.soundGroups) {
      return _.filter(this.soundGroups, (sg: any) => {
        const flags = sg.sound_flag
          .toString(2)
          .split('')
          .reverse()
          .map((flag: any) => parseInt(flag, 2));
        return flags[0];
      });
    }
    return null;
  }

  get filteredNetworks(): any {
    if (this.networks) {
      // @ts-ignore
      const networks = _.values(this.networks, []).filter(
        (network: any) =>
          (network.i18n_name && network.i18n_name.includes(this.searchNetworkText)) ||
          (network.dflt_name && network.dflt_name.includes(this.searchNetworkText)) ||
          (network.i18n_info && network.i18n_info.includes(this.searchNetworkText)) ||
          (network.dflt_info && network.dflt_info.includes(this.searchNetworkText))
      );
      return networks;
    }
    return null;
  }

  selectSoundGroup(soundGroup: any) {
    if (!this.selectedSoundGroup || this.selectedSoundGroup.snd_grp_id != soundGroup.snd_grp_id) {
      this.stopSounds();
      this.selectedSoundGroup = soundGroup;
      this.playSounds();
    }
  }

  playSounds() {
    this.stopSound();

    const delays = _.get(this.selectedSoundGroup, 'sound_delay_ms', []);

    if (this.playingSoundIndex < 0) {
      this.playingSoundIndex = 0;
    }
    if (delays.length > 0 && this.playingSoundIndex < delays.length - 1) {
      this.isPlayingAllSound = true;
      this.delayTimeout = setTimeout(() => {
        this.playSoundById(
          this.selectedSoundGroup['sounds'][this.playingSoundIndex],
          this.playingSoundIndex
        );
      }, delays[this.playingSoundIndex]);
    } else {
      this.stopSounds();
    }
  }

  stopSounds() {
    this.stopSound();
    clearTimeout(this.delayTimeout);
    this.delayTimeout = null;
    this.playingSoundIndex = -1;
    this.isPlayingAllSound = false;
    this.selectedSoundGroup = null;
  }

  playSoundById(id: any, index: number) {
    const sound = _.find(this.sounds, (s: any) => {
      return s.snd_id == id;
    });
    if (sound) {
      this.playSound(sound);
      this.playingSoundIndex = index;
    }
  }

  playSound(sound: any) {
    this.stopSound();
    if (sound.snd_url) {
      let audio = new Audio(sound.snd_url);
      this.playingSound = {
        id: sound.snd_id,
        audio: audio,
      };
      audio.play();
      audio.onended = () => {
        this.playingSound = null;

        if (this.isPlayingAllSound) {
          this.playingSoundIndex++;
          this.playSounds();
        }
      };
    }
  }

  stopSound() {
    if (this.playingSound) {
      this.playingSound.audio.pause();
      this.playingSound = null;
      if (!this.isPlayingAllSound) {
        this.playingSoundIndex = -1;
        this.isPlayingAllSound = false;
      }
    }
  }

  isSelectedNetwork(network: any): boolean {
    if (this.selectedNetwork) {
      return (
        `${network.sys_id},${network.lyr_id},${network.net_id}` ===
        `${this.selectedNetwork.sys_id},${this.selectedNetwork.lyr_id},${this.selectedNetwork.net_id}`
      );
    }
    return false;
  }

  selectNetwork(network: any) {
    this.selectedNetwork = network;
    this.currentNetworkVersion = null;
    this.loadNetworkVersions();
  }

  loadNetworkVersions() {
    if (this.selectedNetwork) {
      getNetworkVersions(this.opId, this.selectedNetwork)
        .then(res => {
          this.networkVersions = res.data;
          this.loadCurrentNetworkVersion();
        })
        .catch(err => {})
        .finally(() => {});
    }
  }

  loadCurrentNetworkVersion() {
    getNetworkCurrentVersion(this.opId, this.selectedNetwork)
      .then(res => {
        this.currentNetworkVersion = res.data;
      })
      .catch(err => {})
      .finally(() => {});
  }

  selectVersion(version: any) {
    this.selectedVersion = version;
    this.isAssignAutomaticSoundPopupVisible = true;
  }

  isCurrentNetworkVersion(version: any) {
    if (this.currentNetworkVersion) {
      return (
        `${this.currentNetworkVersion.sys_id},${this.currentNetworkVersion.lyr_id},${this.currentNetworkVersion.net_id},${this.currentNetworkVersion.net_ver}` ==
        `${version.sys_id},${version.lyr_id},${version.net_id},${version.net_ver}`
      );
    }
    return false;
  }

  removeGlobalSoundGroup(sg: any) {
    this.isRemovingGlobalSoundGroup = true;
    putSoundGroup(this.opId, sg.snd_grp_id, {
      name: sg.name,
      sound_flag: 0,
    })
      .then(() => {
        this.loadSoundGroups();
      })
      .catch(err => {})
      .finally(() => {
        this.isRemovingGlobalSoundGroup = false;
      });
  }

  onSearchNetworkTextChanged(text: string) {
    this.searchNetworkText = text;
  }

  mounted() {
    this.loadNetworks();
  }
}

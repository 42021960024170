
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

// @ts-ignore
import moment from 'moment';
// @ts-ignore
import _ from 'lodash-es';

import SearchTextField from '@/components/form/SearchTextField.vue';
import { formattedDuration } from '@/utils/time';
import SoundUploadPopup from '@/components/sound-editor/SoundUploadPopup.vue';
import DeleteSoundPopup from '@/components/sound-editor/DeleteSoundPopup.vue';

@Component({
  components: {
    SearchTextField,
    SoundUploadPopup,
    DeleteSoundPopup,
  },
})
export default class Sound extends Vue {
  @State opId!: number;
  @State sounds!: any[];

  @Action loadSounds!: () => Promise<void>;

  soundsDuration: any = {};
  searchText: string = '';

  sortKey: string = 'none';
  sortMode: string = 'none';
  playingSound: any | null = null;
  isMultipleSelection: boolean = false;
  isAllSelected: boolean = false;
  selectedSounds: any = [];
  toBeDeletedSounds: any = [];

  isUploadPopupVisible: Boolean = false;
  isDeletePopupVisible: Boolean = false;

  get soundList(): any | null {
    if (this.sounds) {
      const sounds = _.filter(this.sounds, (s: any) => {
        return (s.name || '').toLowerCase().includes(this.searchText.toLowerCase());
      });

      if (this.sortKey != 'none') {
        const sortedSounds = this.getSortedSounds(sounds, this.sortKey);
        if (this.sortMode != 'none') {
          return this.sortMode == 'asc' ? sortedSounds : sortedSounds.reverse();
        }
        return sortedSounds;
      }

      return sounds;
    }
    return null;
  }

  loadData() {
    this.loadSounds();
  }

  loadSoundsDuration() {
    const a = _.map(this.sounds, 'snd_url');
    _.each(this.sounds, (sound: any) => {
      let audio = new Audio(sound.snd_url);
      audio.onloadedmetadata = () => {
        this.$set(this.soundsDuration, sound.snd_id, audio.duration);
      };
    });
  }

  toggleSelectAll() {
    this.searchText = '';
    // @ts-ignore
    this.$refs.searchTextField.clearText();
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.selectedSounds = _.cloneDeep(this.sounds);
    } else {
      this.selectedSounds = [];
    }
  }

  toggleSelectSound(sound: any) {
    if (this.isSoundSelected(sound)) {
      this.isAllSelected = false;
      this.selectedSounds = _.filter(this.selectedSounds, (s: any) => {
        return s.snd_id != sound.snd_id;
      });
    } else {
      this.selectedSounds.push(sound);
    }
  }

  isSoundSelected(sound: any) {
    if (_.findIndex(this.selectedSounds, sound) != -1) {
      return true;
    }
    return false;
  }

  onSearchTextChanged(text: string) {
    this.searchText = text;
  }

  getFormattedUploadDateString(dateString: string): string | null {
    let correctedDateString = dateString;
    if (!correctedDateString.includes('Z')) {
      correctedDateString = `${correctedDateString}Z`;
    }
    return moment(correctedDateString).format('DD/MM/YYYY \xa0H:mm');
  }

  sort(key: string) {
    if (this.sortKey == key) {
      if (this.sortMode == 'none') {
        this.sortMode = 'asc';
      } else if (this.sortMode == 'asc') {
        this.sortMode = 'desc';
      } else {
        this.sortMode = 'none';
        this.sortKey = 'none';
      }
    } else {
      this.sortKey = key;
      this.sortMode = 'asc';
    }
  }

  playSound(sound: any) {
    this.stopSound();
    if (sound.snd_url) {
      let audio = new Audio(sound.snd_url);
      this.playingSound = {
        id: sound.snd_id,
        audio: audio,
      };
      audio.play();
      audio.onended = () => {
        this.playingSound = null;
      };
    }
  }

  stopSound() {
    if (this.playingSound) {
      this.playingSound.audio.pause();
      this.playingSound = null;
    }
  }

  getSoundDuration(sound: any) {
    if (sound) {
      return formattedDuration(sound.duration * 1000);
    }
    return null;
  }

  getSortedSounds(sounds: any, sortKey: any): any {
    if (sortKey == 'add_at') {
      const sortedSounds = sounds
        .slice()
        .sort((a: any, b: any) => moment(a[sortKey]).diff(moment(b[sortKey])));
      return sortedSounds;
    } else if (sortKey == 'name') {
      const sortedSounds = sounds.slice().sort((a: any, b: any) =>
        (a[this.sortKey] || '').localeCompare(b[this.sortKey] || '', undefined, {
          sensitivity: 'base',
          numeric: true,
        })
      );
      return sortedSounds;
    } else if (sortKey == 'duration') {
      const sortedSounds = sounds.slice().sort((a: any, b: any) => a.duration > b.duration);
      return sortedSounds;
    }
  }

  getSortIconName(sortKey: any): string {
    if (this.sortKey == sortKey) {
      if (this.sortMode == 'asc') {
        return 'ic-sort-up.svg';
      } else if (this.sortMode == 'desc') {
        return 'ic-sort-dn.svg';
      }
    }
    return 'ic-sort.svg';
  }

  toggleMultipleSelection() {
    this.isMultipleSelection = !this.isMultipleSelection;
    if (!this.isMultipleSelection) {
      this.isAllSelected = false;
      this.selectedSounds = [];
    }
  }

  onClickDeleteMultipleButton() {
    this.stopSound();
    this.toBeDeletedSounds = _.cloneDeep(this.selectedSounds);
    this.isDeletePopupVisible = true;
  }

  onClickDeleteButton(sound: any) {
    this.stopSound();
    this.toBeDeletedSounds = [sound];
    this.isDeletePopupVisible = true;
  }

  onClickUploadButton() {
    this.isUploadPopupVisible = !this.isUploadPopupVisible;
  }

  onSoundUploaded() {
    this.isUploadPopupVisible = false;
    this.isDeletePopupVisible = false;
    this.loadData();
  }

  onSoundDeleted(sounds: any) {
    this.isUploadPopupVisible = false;
    this.isDeletePopupVisible = false;

    this.selectedSounds = _.remove(
      this.selectedSounds,
      (sound: any) => _.map(sounds, 'snd_id').indexOf(sound.snd_id) < 0
    );

    this.loadData();
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// @ts-ignore
import moment from 'moment';

const TYPE_MINUTE_TO_MILLISECOND = 'minuteToMillisecond';

@Component
export default class TimeTextField extends Vue {
  @Prop() readonly type!: string | 'default';
  @Prop() readonly initMs!: number;
  @Prop() readonly elementIndex!: number;
  @Prop() readonly showMs!: boolean;

  @Prop({ default: true }) readonly enabled!: boolean;

  minuteString: string = '00';
  secondString: string = '00';
  millisecondString: string = '0';

  @Watch('initMs')
  onInitMsChanged() {
    this.updateData();
  }

  correctMinute() {
    let value = parseInt(this.minuteString);
    if (!value) {
      value = 0;
    }
    if (this.type == TYPE_MINUTE_TO_MILLISECOND) {
      const displayValue = value < 10 ? `0${value}` : `${value}`;
      this.minuteString = displayValue;
    }
    this.onTimeChanged();
  }

  correctSecond() {
    let value = parseInt(this.secondString);
    if (!value) {
      value = 0;
    } else {
      if (value > 59) {
        value = 59;
      }
    }
    if (this.type == TYPE_MINUTE_TO_MILLISECOND) {
      const displayValue = value < 10 ? `0${value}` : `${value}`;
      this.secondString = displayValue;
    }
    this.onTimeChanged();
  }

  correctMillisecond() {
    this.onTimeChanged();
  }

  onClickIncreaseButton() {
    if (this.type == TYPE_MINUTE_TO_MILLISECOND) {
      let ms: number = parseInt(this.millisecondString);
      let s: number = parseInt(this.secondString);
      let m: number = parseInt(this.minuteString);
      if (this.showMs) {
        ms = (ms + 1) % 10;
      }
      if (ms == 0) {
        s = (s + 1) % 60;
        if (s == 0) {
          m = m + 1;
        }
      }
      this.millisecondString = `${ms}`;
      this.secondString = `${s}`;
      this.minuteString = `${m}`;
      this.correctMillisecond();
      this.correctSecond();
      this.correctMinute();
    }
  }

  onClickDecreaseButton() {
    if (this.type == TYPE_MINUTE_TO_MILLISECOND) {
      if (
        parseInt(this.minuteString) == 0 &&
        parseInt(this.secondString) == 0 &&
        parseInt(this.millisecondString) == 0
      ) {
        return;
      }

      let ms: number = parseInt(this.millisecondString);
      let s: number = parseInt(this.secondString);
      let m: number = parseInt(this.minuteString);
      if (this.showMs) {
        ms = (ms + 9) % 10;
      }
      if (ms == 9 || !this.showMs) {
        s = (s + 59) % 60;
        if (s == 59) {
          m = Math.max(0, m - 1);
        }
      }
      this.millisecondString = `${ms}`;
      this.secondString = `${s}`;
      this.minuteString = `${m}`;
      this.correctMillisecond();
      this.correctSecond();
      this.correctMinute();
    }
  }

  onTimeChanged() {
    if (this.type == TYPE_MINUTE_TO_MILLISECOND) {
      const minuteMs = parseInt(this.minuteString) * 60000;
      const secondMs = parseInt(this.secondString) * 1000;
      const ms = parseInt(this.millisecondString) * 100;
      const totalMs = minuteMs + secondMs + ms;
      const result = { totalMs: totalMs, elementIndex: this.elementIndex };
      this.$emit('onChanged', result);
    }
  }

  updateData() {
    if (this.type == TYPE_MINUTE_TO_MILLISECOND) {
      const minute: number = Math.floor(this.initMs / 60000);
      this.minuteString = minute < 10 ? `0${minute}` : `${minute}`;
      const second: number = Math.floor(this.initMs / 1000) % 60;
      this.secondString = second < 10 ? `0${second}` : `${second}`;
      this.millisecondString = `${Math.floor((this.initMs % 1000) / 100)}`;
    }
  }

  mounted() {
    this.updateData();
  }
}

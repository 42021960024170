
import { Component, Vue } from 'vue-property-decorator';
import Sound from '@/components/sound-editor/Sound.vue';
import SoundGroup from '@/components/sound-editor/SoundGroup.vue';
import AssignSound from '@/components/sound-editor/AssignSound.vue';

@Component({
  components: {
    Sound,
    SoundGroup,
    AssignSound,
  },
})
export default class SoundEditor extends Vue {
  selectedMenu: number = 2;

  get Menus(): any {
    return [
      { name: 'assign-sound', title: 'กำหนดเสียง' },
      { name: 'sound-group', title: 'กลุ่มเสียง' },
      { name: 'sound', title: 'ไฟล์เสียง' },
    ];
  }

  selectMenu(index: number) {
    if (this.selectedMenu != index) {
      if (this.selectedMenu == 2) {
        // @ts-ignore
        this.$refs.sound.stopSound();
      } else if (this.selectedMenu == 1) {
        // @ts-ignore
        this.$refs.soundGroup.stopSound();
        // @ts-ignore
        this.$refs.soundGroup.stopSounds();
      } else if (this.selectedMenu == 0) {
        // @ts-ignore
        this.$refs.assignSound.stopSound();
        // @ts-ignore
        this.$refs.assignSound.stopSounds();
      }
      this.selectedMenu = index;
    }
  }
}
